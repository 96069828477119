<template>
  <div>
    <div class="vip-type-list" style="font-size:16px">
      <span>{{this.$route.query.name}}- 提成详情（ {{this.$route.query.dateType == '班次'?this.$route.query.dateType:''}}  {{this.$route.query.dateMin }} <span v-if="this.$route.query.dateType!== '班次'">至 {{this.$route.query.dateMax}}</span> ）</span>
      <div style="display:flex;align-items:center;">
        <el-button type="success" @click="this.$router.go(-1)">返回</el-button>
      </div>
    </div>
    <main  style="margin:0 20px">
      <div class="vip-type-list" style="font-size:14px">
        <span style="font-size:14px">销售总额：{{salesAmount}}    提成金额：{{royalty}} 
             （ 商品提成：{{Number(goodsRoyalty).toFixed(2)}}    服务提成：{{Number(serviceRoyalty).toFixed(2)}} ）</span>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="tableDataTwo" 
          border  
          style="width: 100%" 
          :row-style="rowStyleTwo"
          :header-cell-style="headerStyleTwo"
        >
          <el-table-column prop="itemName" label="名称" />
          <el-table-column prop="saleCount" label="销售数量" />
          <el-table-column label="销售金额" >
            <template #default="props">
              <span>{{(Number(props.row.salesAmount)).toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="提成金额">
            <template #default="props">
              <span>{{(Number(props.row.royalty)).toFixed(2)}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </main>
    <Print rul='report/exportAchievementCate.json' :add='"&waiterId="+this.$route.query.waiterId' />
  </div>
</template>

<script>
import {ref,watch} from 'vue'
import {useRoute} from'vue-router'
import {postData} from '@a'
export default {
  setup(){
// 分类中的
    let route = useRoute()
    console.log(route.query)
    let tableDataTwo = ref([
      {
        date: '2016-05-03',
        tableName: '大厅',
        state: 1,
        address: 'No. ',
      },
      {
        date: '2016-05-03',
        tableName: '大厅1',
        address: 'No. 189,',
      },
      {
        date: '2016-05-03',
        tableName: '大厅2',
        address: 'No. 189,',
      },
      {
        date: '2016-05-03',
        tableName: '大厅',
        state: 1,
        address: 'No. ',
      },
      {
        date: '2016-05-03',
        tableName: '大厅1',
        address: 'No. 189,',
      },
      {
        date: '2016-05-03',
        tableName: '大厅2',
        address: 'No. 189,',
      },
    ])
    const rowStyleTwo =({ row})=>{
      let style={}
      style['text-indent'] = '2em'
      style['font-size'] = '14px'
      if(row.state == 1){
            style['background-color'] = '#F2F2F2'
            style['text-indent'] = '1em'
            style['font-size'] = '14px'
            style['font-weight'] = '600'
      }
      return style
    }
    const headerStyleTwo =()=>{
      let style={}
      style.color = "#333"
      style['font-size'] = '14px'
      style['background-color'] = '#E4E4E4'
      return style
    }
  let salesAmount = ref(0)
  let royalty = ref(0)
  let goodsRoyalty = ref(0)
  let serviceRoyalty = ref(0)
  watch(()=>route.query,()=>{
    if(route.name === 'deductStaff')
    info()
  })
  const info = async()=>{
    try {
      let param = {
        waiterId:route.query.waiterId,
        shopId:route.query.shopId,
      }
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyId = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))        }
      }
      salesAmount.value = 0
      royalty.value = 0
      goodsRoyalty.value = 0
      serviceRoyalty.value = 0
      tableDataTwo.value = []
      param.type = 0
      let res1 = await(postData('report/achievement/cate.json',param))
      param.type = 1
      let res2 = await(postData('report/achievement/cate.json',param))
      param.type = 2
      let res3 = await(postData('report/achievement/cate.json',param))
      tableDataTwo.value = []
        tableDataTwo.value.push({itemName:'商品',state:1,saleCount:0,salesAmount:0,royalty:0})
        let sub = 0
        for(let i = 0;i<res1.data.item.length;i++){
          tableDataTwo.value.push(res1.data.item[i])
          tableDataTwo.value[tableDataTwo.value.length-1].salesAmount = +(Number(tableDataTwo.value[tableDataTwo.value.length-1].salesAmount).toFixed(2))
          tableDataTwo.value[tableDataTwo.value.length-1].royalty = +(Number(tableDataTwo.value[tableDataTwo.value.length-1].royalty).toFixed(2))
          tableDataTwo.value[sub].saleCount = Number(tableDataTwo.value[sub].saleCount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].saleCount)
          tableDataTwo.value[sub].salesAmount = Number(tableDataTwo.value[sub].salesAmount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].salesAmount)
          tableDataTwo.value[sub].royalty = Number(tableDataTwo.value[sub].royalty) + Number(tableDataTwo.value[tableDataTwo.value.length-1].royalty)
          tableDataTwo.value[sub].salesAmount = +(Number(tableDataTwo.value[sub].salesAmount).toFixed(2))
          tableDataTwo.value[sub].royalty = +(Number(tableDataTwo.value[sub].royalty).toFixed(2))
        }
        tableDataTwo.value.push({itemName:'服务',state:1,saleCount:0,salesAmount:0,royalty:0})
        sub = tableDataTwo.value.length - 1
        for(let i = 0;i<res2.data.item.length;i++){
          tableDataTwo.value.push(res2.data.item[i])
          tableDataTwo.value[tableDataTwo.value.length-1].salesAmount = +(Number(tableDataTwo.value[tableDataTwo.value.length-1].salesAmount).toFixed(2))
          tableDataTwo.value[tableDataTwo.value.length-1].royalty = +(Number(tableDataTwo.value[tableDataTwo.value.length-1].royalty).toFixed(2))
          tableDataTwo.value[sub].saleCount = Number(tableDataTwo.value[sub].saleCount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].saleCount)
          tableDataTwo.value[sub].salesAmount = Number(tableDataTwo.value[sub].salesAmount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].salesAmount)
          tableDataTwo.value[sub].royalty = Number(tableDataTwo.value[sub].royalty) + Number(tableDataTwo.value[tableDataTwo.value.length-1].royalty)
          tableDataTwo.value[sub].salesAmount = +(Number(tableDataTwo.value[sub].salesAmount).toFixed(2))
          tableDataTwo.value[sub].royalty = +(Number(tableDataTwo.value[sub].royalty).toFixed(2))
        }
        tableDataTwo.value.push({itemName:'套餐',state:1,saleCount:0,salesAmount:0,royalty:0})
        sub = tableDataTwo.value.length - 1
        for(let i = 0;i<res3.data.item.length;i++){
          tableDataTwo.value.push(res3.data.item[i])
          tableDataTwo.value[tableDataTwo.value.length-1].salesAmount = +(Number(tableDataTwo.value[tableDataTwo.value.length-1].salesAmount).toFixed(2))
          tableDataTwo.value[tableDataTwo.value.length-1].royalty = +(Number(tableDataTwo.value[tableDataTwo.value.length-1].royalty).toFixed(2))
          tableDataTwo.value[sub].saleCount = Number(tableDataTwo.value[sub].saleCount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].saleCount)
          tableDataTwo.value[sub].salesAmount = Number(tableDataTwo.value[sub].salesAmount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].salesAmount)
          tableDataTwo.value[sub].royalty = Number(tableDataTwo.value[sub].royalty) + Number(tableDataTwo.value[tableDataTwo.value.length-1].royalty)
          tableDataTwo.value[sub].salesAmount = +(Number(tableDataTwo.value[sub].salesAmount).toFixed(2))
          tableDataTwo.value[sub].royalty = +(Number(tableDataTwo.value[sub].royalty).toFixed(2))
        }
        tableDataTwo.value.push({itemName:'充值',state:1,saleCount:0,salesAmount:0,royalty:0})
        tableDataTwo.value.push({itemName:'预约',state:1,saleCount:0,salesAmount:0,royalty:0})
        tableDataTwo.value.forEach((e) => {
          //  e.check = '查看'
          if(!e.state){
            e.check = '查看'
          }else{
            salesAmount.value += Number(e.salesAmount)
            royalty.value += Number(e.royalty)
          }
          if(e.itemName == '服务' && e.state){
            serviceRoyalty.value = e.royalty
          }
          if(e.itemName == '商品' && e.state){
            goodsRoyalty.value  = e.royalty
          }
        });
    } catch (error) {
      console.log(error)
    }

    }
    info()
    return{
      tableDataTwo,rowStyleTwo,headerStyleTwo,
      salesAmount,
      royalty,
      goodsRoyalty,
      serviceRoyalty,
    }
  }
}
</script>
<style scoped>
.vip-type-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 10px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}


.date-box{
    width: 274px;
    height: 32px;
    margin-right: 20px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-left{
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  border-right: 1px solid #b4b4b4;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
}
.box-right{
  text-align: right;
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  font-size: 16px;
  line-height: 25px;
  border-left: 1px solid #b4b4b4;
  cursor: pointer;
}
</style>